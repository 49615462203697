import React from 'react'
import { getCurrentYear } from '../../utils/functions'
import { Wrapper, Item } from './style'

const Footer = () =>
  <Wrapper>
    <Item position='left'>
      © {getCurrentYear()}
    </Item>
    <Item position='right'>
      <a target="_blank" rel="noopener noreferrer" href='/lab'>Lab</a>
    </Item>
  </Wrapper>

export default Footer
