import React from 'react'
import EmailMe from '../EmailMe'
import { Wrapper, Nav, NavItem, NavLink } from './style'

const Menu = () =>
  <Wrapper>
    <Nav>
      <NavItem>
        <a href="https://twitter.com/hiranpatel" rel="noopener noreferrer" target="_blank">Twitter</a>
      </NavItem>
    </Nav>
    <Nav>
      <NavItem>
        <NavLink to='/'>Index</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to='/covid19'>SARS-CoV-2</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to='/longevity'>Longevity</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to='/blog'>Blog</NavLink>
      </NavItem>
      <NavItem><EmailMe text="Contact"/>
      </NavItem>
    </Nav>
  </Wrapper>

export default Menu
