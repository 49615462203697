export const LAMBDA_ENDPOINT = 'https://k.execute-api.us-east-1.amazonaws.com/prod/distance'
export const IS_PROD = process.env.NODE_ENV === 'production'
export const META = {
  common: {
    image: '/images/michele-mazzucco-meta-image.jpg',
  },
  index: {
    title: 'Hiran Patel',
    description: 'Thoughts. Research. Musings.'
  },
  profile: {
    title: 'Hiran Patel | Profile',
    description: ' '
  },
  longevity: {
    title: 'Hiran Patel | Longevity',
    description: 'A hub for research and findings on all things longevity'
  },
  blog: {
    title: 'Hiran Patel | Blog',
  }
}
